<template>
  <div class="membersort">
    <van-nav-bar
      left-arrow
      @click-left="onClickLeft"
      fixed
    >
      <template #title>
        <form action="/">
          <van-search
            v-model="keywords"
            show-action
            shape="round"
            placeholder="请搜索邀请码"
            @search="onSearch"
          >
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </form>
      </template>
    </van-nav-bar>
    <div class="main">
      <div v-if="!isnodata">
        <van-cell center v-for="(item, index) in list" :key="index">
          <template #icon>
            <van-tag class="sort_item">{{item.sort}}</van-tag>
          </template>
          <template #title>
            <van-image
              :src="item.avatar"
              width="60"
              height="60"
              round
              fit="cover"
              lazy-load
              @click="orderList(index)"
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
          </template>
          <template #default>
            <div class="list_title" @click="orderList(index)">
              <div class="van-ellipsis list_title_text">{{item.nickname}}</div>
              <div class="list_title_tag">
                <van-tag
                  size="medium"
                  color="pink"
                  v-if="item.lock_shop >= 0 && item.lock_shop == info.id"
                >粉</van-tag>
                <van-tag
                  size="medium"
                  color="#74baed"
                  v-if="item.lock_shop == -1"
                >未</van-tag>
                <van-tag
                  size="medium"
                  color="#000000"
                  v-if="item.lock_shop >= 0 && item.lock_shop != info.id"
                >禁</van-tag>
                <van-tag
                  size="medium"
                  color="#FFA500"
                >&yen; {{item.price}}</van-tag>
              </div>
            </div>
            <div class="level_list">
              <van-tag round color="#FDE7EA" text-color="#F23A5E">{{item.levelname}}</van-tag>
              <van-tag round color="#fffbe8" text-color="#ed6a0c" @click="copayAgentCode(item.agent_code)">邀请码：{{item.agent_code}}</van-tag>
            </div>
          </template>
          <template #right-icon>
            <div>
              <div>
                <van-button
                  type="danger"
                  size="mini"
                  plain
                  round
                  @click="showLvelList(index)"
                >
                  设置
                </van-button>
              </div>
              <div style="margin-top: 10px;" v-if="info.is_kaohe == 1">
                <van-button
                  size="mini"
                  round
                  :color="item.check_level == 0 ? '#999999' : '#1989fa'"
                  @click="showCheckLevel(index)"
                >
                  {{item.check_level == 0 ? '不考核' : '考核'}}
                </van-button>
              </div>
            </div>
          </template>
        </van-cell>
        <van-divider v-if="nofinished">没有更多啦</van-divider>
      </div>
      <van-empty v-if="isnodata" description="暂无数据" />
    </div>
    <van-action-sheet
      v-model="levelshow"
      :actions="actions"
      cancel-text="取消"
      title="选择等级"
      close-on-click-action
      @cancel="onLevelCancel"
      @select="onLevelSelect"
      get-container=".membersort"
    />
    <!-- 设置不考核弹框 -->
    <van-dialog
      v-model="showsetkaohe"
      :title="setKaoheTitle" 
      show-cancel-button
      :before-close="doCheckLevel"
    >
      <div class="checeLevel">
        <van-field
          v-model="checkLevelFrom.nickname"
          label="用户昵称："
          :border="false"
          readonly
        />
        <van-field
          v-model="checkLevelFrom.money"
          label="收款金额："
        />
        <van-field
          label="收款凭证："
          :border="false"
        >
          <template #input>
            <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead" />
          </template>
        </van-field>
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "MemberSort",
  data () {
    return {
      list: [],
      keywords: '',
      isnodata: false,
      levelshow: false, // 默认隐藏等级选择弹框
      actions: [
        { name: '顶级会员', level: 1, className: "levelTan_list" },
        { name: '特级会员', level: 6, className: "levelTan_list" },
        { name: '普通会员', level: 0, className: "levelTan_list" }
      ],
      member: {}, // 已选用户
      index: -1, // 已选用户索引
      isLoading: false,
      setTeji: false, // 是否设置中
      info: {},
      showsetkaohe: false, // 设置是否考核弹框默认隐藏
      fileList: [], // 图片上传预览
      checkLevelFrom: {
        index: -1,
        nickname: "",
        memberid: 0,
        money: "",
        'money_img': "",
        "check_level": 1,
        level: 0
      },
      setKaoheTitle: "",
      isRefresh: false, 
      noloading: false,
      nofinished: false
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      console.log("init");
      this.getinfo();
      this.getList();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            console.log(that.info);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    getList() {
      let that = null;
      that = this;
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '搜索中...',
      });
      this.nofinished = false;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + 'shopsmobile/order/getMemberOrderSort',
          that.$qs.stringify({
            keywords: that.keywords
          })
        )
        .then(res => {
          console.log(res);
          Toast.clear();
          that.nofinished = true;
          if (res.data.code == 100000) {
            that.list = res.data.data;
            that.isnodata = false;
          }else {
            that.isnodata = true;
          }
        })
        .catch(err => {
          that.isnodata = true;
          Toast.clear();
          console.log(err);
        })
    },
    onClickLeft() {
      this.$router.back();
    },
    onSearch() {
      console.log("搜索");
      this.getList();
    },
    onCancel() {
      console.log("取消");
    },
    orderList(index) {
      const member = this.list[index];
      this.$router.push({
        path: "/order",
        query: {
          memberid: member.id
        }
      })
    },
    // 显示设置等级弹框
    showLvelList(index) {
      this.member = this.list[index];
      this.index = index;
      this.levelshow = true;
      console.log(index);
    },
    // 取消选择等级
    onLevelCancel() {
      console.log("取消选择等级");
    },
    onLevelSelect(item) {
      console.log("选择等级", item);
      if (parseInt(this.info.is_kaohe) == 0) {
        if (this.member.level == 1) {
          if (item.level != 1) {
            this.$toast("无法修改，不可降级");
            return false;
          }
        }else if(this.member.level == 6) {
          if (item.level == 0) {
            this.$toast("无法修改，不可降级");
            return false;
          }
        }
      }

      console.log(this.member);
      if (this.member.hehuoren && this.member.hehuoren >= 0) {
        this.setLevel(item);
      }else if (parseInt(this.member.level) !== 1 && item.level == 1 && this.member.check_level == 0) {
        this.checkLevelFrom = {
          index: this.index,
          nickname: this.member.nickname,
          memberid: this.member.id,
          money: "",
          'money_img': "",
          "check_level": 1,
          level: item.level,
          name: item.name
        }
        this.setKaoheTitle = "升为顶级";
        this.showsetkaohe = true;
      }else {
        this.setLevel(item);
      }
    },
    // 执行修改等级操作
    setLevel(item) {
      // 执行修改等级操作
      let that = null;
      that = this;
      let name = '';
      if (this.member.nickname) {
        name = this.member.nickname;
      }else {
        name = '未设置昵称';
      }

      that.$dialog.confirm({
        title: '确认更改等级',
        message: '确认要更改【'+name+'】的等级吗？',
      })
      .then(() => {
        // on confirm
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_level",
            that.$qs.stringify({
              level: item.level,
              memberid: that.member.id
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "修改成功");
              that.list[that.index].levelname = item.name;
              that.list[that.index].level = item.level;
            }else {
              that.$toast(res.data.msg ? res.data.msg : "修改失败");
            }
          })
          .catch(err => {
            console.log(err);
          })
      })
      .catch(() => {
        // on cancel
      });
        
    },
    // 复制邀请码
    copayAgentCode(code) {
      let _that = null;
      _that = this;
      _that.$copyText(code)
      .then(
        function(e) {
          _that.$toast("邀请码复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    // 特级白名单
    changeTeji(e) {
      console.log(e);
      let that = null;
      that = this;
      let id = 0;
      let status = 0;
      id = that.list[e].id;
      status = that.list[e].teji_white;
      if (!that.setTeji) {
        that.setTeji = true;
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_teji_white",
            that.$qs.stringify({
              id: id,
              status: status
            })
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "设置成功");
              that.list[e]['teji_white'] = 1-status;
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
            }
          })
          .catch(err => {
            console.log(err);
            that.setTeji = false;
          })
      }
    },
    // 设置是否考核
    showCheckLevel(index) {
      let that = null;
      that = this;
      this.fileList = [];
      that.checkLevelFrom = {
        index: index,
        memberid: that.list[index].id,
        nickname: that.list[index].nickname,
        money: "",
        'money_img': "",
        'check_level': that.list[index].check_level,
        level: that.list[index].level,
      }
      
      if (that.list[index].check_level == 1 && that.list[index].level == 1) {
        that.setKaoheTitle = "设为免审核";
        that.showsetkaohe = true;
      }else {
        that.$dialog.confirm({
          title: '是否要重新调整为考核业绩',
          showCancelButton: true,
          beforeClose: (action, done) => {
            console.log(action);
            if (action == 'confirm') {
              that.doCheckLevel(action, done);
            }
            done();
          }
        })
      }
    },
    // 上传图片
    afterRead(file) {
      let that = null;
      that = this;
      console.log(file);
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/shopkaohe/zhuanzhang');
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.checkLevelFrom['money_img'] = response.data.img_url;
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 执行考核
    doCheckLevel(action, done) {
      let that = null;
      that = this;
      
      if(action == 'confirm'){
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_check_level",
            that.$qs.stringify(that.checkLevelFrom)
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              if (that.checkLevelFrom.name) {
                that.setLevel(that.checkLevelFrom);
              }else{
                that.$toast(res.data.msg ? res.data.msg : "设置成功");
              }
              if (that.checkLevelFrom.check_level==1) {
                that.list[that.checkLevelFrom.index]['check_level'] = 0;
              }else{
                that.list[that.checkLevelFrom.index]['check_level'] = 1;
              }
              
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
              done(false);
            }
          })
          .catch(err => {
            console.log(err);
            // that.setTeji = false;
            done(false);
          })
      }else {
        done();
      }
    },
  }
}
</script>
<style lang="less">
.membersort {
  width: 100%;
  min-height: 100%;
  overflow: hidden;
  background-color: #f8f8f8;
  // 头部样式
  .van-nav-bar {
    .van-nav-bar__title {
      max-width: 100%;
      flex: 1;
      .van-search {
        padding: 10px 0;
        .van-search__action {
          padding: 0 16px;
        }
        .van-cell__value {
          flex: 1;
        }
      }
    }
    .van-nav-bar__left {
      position: static;
    }
  }
  .main {
    width: 100%;
    overflow: hidden;
    padding-top: 50px;
    .van-cell {
      .sort_item {
        padding: 5px 10px;
        margin-right: 10px;
      }
      .van-cell__title {
        padding: 0 10px 0 0;
        overflow: hidden;
        .van-image {
          display: block;
        }
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        .list_title {
          display: flex;
          overflow: hidden;
          .list_title_text {
            overflow: hidden;
            flex: 1;
          }
          .list_title_tag {
            margin: 0 10px;
            .van-tag {
              margin-right: 5px;
              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }
        .level_list {
          margin-top: 5px;
          display: flex;
          justify-items: center;
          .van-tag {
            padding: 1px 5px;
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
      .van-button {
        padding: 0 10px;
        font-size: 13px;
      }
    }
    .van-cell:first-of-type {
      .sort_item {
        background-color: #f00;
      }
    }
    .van-cell:nth-of-type(2) {
      .sort_item {
        background-color: orange;
      }
    }
    .van-cell:nth-of-type(3) {
      .sort_item {
        background-color: pink;
      }
    }
  }
}
</style>